import React from 'react';
import { Database, AlertTriangle, Sparkles, Brain, FileWarning, Settings, GitMerge, ChevronsRight, ArrowDownRight, ArrowUpRight, Check, ThumbsUp } from 'lucide-react';

const FlowDiagram = () => {
  const Arrow = ({ className = "" }) => (
    <div className={`flex items-center ${className}`}>
      <div className="w-12 h-0.5 bg-gray-300"></div>
      <ChevronsRight className="text-gray-400 -ml-2" size={24} />
    </div>
  );

  const DiagonalArrow = ({ direction = "down", className = "" }) => {
    const Component = direction === "down" ? ArrowDownRight : ArrowUpRight;
    return (
      <div className={`relative ${className}`}>
        <Component className="text-gray-400" size={24} />
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="w-full h-0.5 bg-gray-300 transform rotate-45"></div>
        </div>
      </div>
    );
  };

  const StepBox = ({ icon, label, subLabel, className = "" }) => (
    <div className={`flex flex-col items-center text-center min-w-32 ${className}`}>
      <div className="p-4 bg-gray-50 rounded-lg mb-2 shadow-sm">
        {React.cloneElement(icon, { size: 32 })}
      </div>
      <div className="text-sm font-medium text-gray-800">{label}</div>
      <div className="text-xs text-gray-500">{subLabel}</div>
    </div>
  );

  return (
    <div className="w-full p-8 bg-white rounded-lg shadow-lg flex justify-center">
      <div className="space-y-20 flex flex-col">
        {/* Scenario 1 */}
        <div className="relative">
          <h3 className="text-lg font-semibold mb-6 text-gray-700">
            Scenario 1: Real Data with Issues
          </h3>
          <div className="flex">
            <StepBox 
              icon={<Database className="text-blue-500" />}
              label="Real Data"
              subLabel="with issues"
            />
            <Arrow />
            <StepBox 
              icon={<Settings className="text-indigo-500" />}
              label="Train AI"
              subLabel="Models"
            />
            <Arrow />
            <StepBox 
              icon={<AlertTriangle className="text-yellow-500" />}
              label="Sub-optimal"
              subLabel="AI Results"
            />
          </div>
        </div>

        <div className="relative">
          <h3 className="text-lg font-semibold mb-6 text-gray-700">
            Scenario 1: Fully Synthetic Data For AI Training
          </h3>
          <div className="flex items-center">
            <StepBox 
              icon={<FileWarning className="text-red-500" />}
              label="Real Data"
              subLabel="Not Available"
            />
            <Arrow />
            <StepBox 
              icon={<Sparkles className="text-purple-500" />}
              label="Synthetic Data"
              subLabel="Initial Data"
            />
            <Arrow />
            <StepBox 
              icon={<Settings className="text-indigo-500" />}
              label="Train AI"
              subLabel="Models"
            />
            <Arrow />
            {/* Trained Model Box */}
            <StepBox 
              icon={<ThumbsUp className="text-green-500" />}
              label="Good Results"
              subLabel="Initial System"
            />
          </div>
        </div>

        <div className="relative">
          <h3 className="text-lg font-semibold mb-6 text-gray-700">
            Scenario 2: Real Data Enhanced with Synthetic Data
          </h3>
          <div className="flex items-center space-x-4">
            {/* Real Data Box */}
            <StepBox 
              icon={<Database className="text-blue-500" />}
              label="Real Data"
              subLabel="with Issues"
            />
            {/* Plus Icon */}
            <div className="text-xl font-bold text-gray-700">+</div>
            {/* Synthetic Data Generator Box */}
            <StepBox 
              icon={<Sparkles className="text-purple-500" />}
              label="Synthetic Data"
              subLabel="Data Enhancement"
            />
            {/* Arrow */}
            <Arrow />
            {/* Trained Model Box */}
            <StepBox 
              icon={<Settings className="text-indigo-500" />}
              label="Train AI"
              subLabel="Models"
            />
            <Arrow />
            {/* Trained Model Box */}
            <StepBox 
              icon={<ThumbsUp className="text-green-500" />}
              label="Improved AI"
              subLabel="Performance"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowDiagram;